import {
    APP_INITIALIZER,
    EnvironmentProviders,
    inject,
    Provider,
} from '@angular/core';
import {
    provideTransloco as provideTranslocoBase,
    TranslocoService,
} from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';

export const provideTransloco = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideTranslocoBase({
            config: {
                availableLangs: ['en', 'de'],
                defaultLang: 'de',
                fallbackLang: 'de',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => translocoService.load(defaultLang);
            },
            multi: true,
        },
    ];
};
