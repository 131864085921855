import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    ENVIRONMENT_INITIALIZER,
    EnvironmentProviders,
    Provider,
    inject,
} from '@angular/core';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { config } from 'app.config';
import { AuthService } from 'app/core/auth/auth.service';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptorsFromDi()),
        provideOAuthClient({
            resourceServer: {
                allowedUrls: config.resourceServerUrls,
                sendAccessToken: true,
            },
        }),
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthService),
            multi: true,
        },
    ];
};
