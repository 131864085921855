import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { config } from 'app.config';
import { FaviconService } from 'app/core/favicon/favicon.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        titleService: Title,
        meta: Meta,
        faviconService: FaviconService
    ) {
        titleService.setTitle(`${config.tenant} Connect`);
        meta.updateTag(
            { name: 'description', content: `${config.tenant} Connect` },
            'name=description'
        );
        faviconService.setFavicon(
            `favicon-${config.tenant}-16x16.png`,
            '16x16'
        );
        faviconService.setFavicon(
            `favicon-${config.tenant}-32x32.png`,
            '32x32'
        );
    }
}
