import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { of } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const hasValidAccessToken = inject(OAuthService).hasValidAccessToken();
    // TODO: check why hasValidAccessToken is returning true even if token is expired
    // check if token is expired by hand
    const tokeExpired =
        inject(OAuthService).getAccessTokenExpiration() < new Date().getTime();
    return of(hasValidAccessToken && !tokeExpired);
};
