import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable()
export class OktopusErrorHandler extends SentryErrorHandler {
  constructor(private _router: Router) {
    super();
  }

  handleError(error: unknown): void {
    super.handleError(error);
    this._router.navigate(['/500']);
  }
}
