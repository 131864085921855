import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, of, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    private _navigationItems: FuseNavigationItem[] = [
        {
            id: 'inbox',
            title: 'Inbox',
            type: 'basic',
            icon: 'heroicons_outline:envelope',
            link: '/notifications',
            badge: {
                classes: 'ml-1 px-2 bg-pink-600 text-white rounded-full',
            },
        },
    ];

    /**
     * Constructor
     */
    constructor(private _translocoService: TranslocoService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
        //   tap((navigation) => {
        //     // TODO reload translation on language change
        //     for (const navigationType of Object.keys(navigation)) {
        //       navigation[navigationType] = navigation[navigationType].map(
        //         (item) => ({
        //           ...item,
        //           title: this._translocoService.translate(`navigation.${item.id}`),
        //         })
        //       );
        //     }
        //     this._navigation.next(navigation);
        //   })
        // );

        // TODO reload translation on language change
        const translatedNavigationItems = this._navigationItems.map((item) => ({
            ...item,
            title: this._translocoService.translate(`navigation.${item.id}`),
        }));

        const navigation = {
            default: translatedNavigationItems,
            compact: translatedNavigationItems,
            futuristic: translatedNavigationItems,
            horizontal: translatedNavigationItems,
        };
        this._navigation.next(navigation);
        return of(navigation);
    }
}
