import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

/**
 * A service to set the favicon.
 */
@Injectable({
    providedIn: 'root',
})
export class FaviconService {
    private renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) private _doc: any,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Set the title of the current HTML document.
     * @param iconURL - Default favicon URL
     */
    setFavicon(iconURL: string, size: string) {
        const link = this.renderer.createElement('link');
        let currentLinkHref = iconURL;

        this.appendLinkTag(link, size, currentLinkHref);
    }

    /**
     * Append new link to HEAD
     * @param link - DOM element
     * @param iconURL - favicon URL
     */
    private appendLinkTag(link: any, size: string, iconURL: string) {
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = iconURL;
        link.sizes = size;
        const head = this._doc.getElementsByTagName('head')[0];
        this.renderer.appendChild(head, link);
    }
}
