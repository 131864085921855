import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from 'app.component';
import { appConfig, config } from 'app.config';

if (isDevMode() === false) {
    Sentry.init({
        dsn: 'https://766f4a8f89364b02a361de5e07bfc165@o309725.ingest.sentry.io/4505150378934272',
        release: config.version,
        environment: config.environment,
    });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);

const image = document.getElementById(
    'splash-screen-image'
) as HTMLImageElement;
image.src =
    config.tenant === 'Praxis'
        ? 'assets/images/logo/logo-Praxis.png'
        : 'assets/images/logo/logo-Alberta.svg';
image.classList.remove('hidden');
