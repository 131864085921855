import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ApplicationConfig, ErrorHandler, LOCALE_ID } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { FuseProviderConfig, provideFuse } from '@fuse';
import { AuthConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { appRoutes } from 'app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { OktopusErrorHandler } from 'error.handler';
import packageJson from '../package.json';

type Environment = Record<
    string,
    { oauthConfig: AuthConfig; baseApiUrl: string }
>;

const environment: Record<string, Environment> = {
    Alberta: {
        local: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                // dev alberta-connect clientId
                clientId: '49a6e0d7-a0a4-4983-b14e-dc4b0253ebe6',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: true,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'http://localhost:3032',
        },
        dev: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: '49a6e0d7-a0a4-4983-b14e-dc4b0253ebe6',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: true,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'https://oktopus.dev.k8s.api.halloalberta.de',
        },
        staging: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: 'f44ac01e-94df-4970-995d-51bbedb976ea',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: false,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'https://oktopus.staging.k8s.api.halloalberta.de',
        },
        prod: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: 'b390279c-3edc-49ab-b43a-cb787dd18a6a',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: false,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'https://oktopus.k8s.api.halloalberta.de',
        },
    },
    Praxis: {
        local: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                // dev praxis-connect clientId
                clientId: '6d642c2a-c29f-4d9f-8f33-4d35ed25571c',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: true,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'http://localhost:3032',
        },
        dev: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: '6d642c2a-c29f-4d9f-8f33-4d35ed25571c',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: true,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'https://praxis.oktopus.dev.k8s.api.halloalberta.de',
        },
        staging: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: 'f17b53e5-a1cf-49d6-9668-cd5dd72c5d69',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: false,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl:
                'https://praxis.oktopus.staging.k8s.api.halloalberta.de',
        },
        prod: {
            oauthConfig: {
                issuer: `https://auth.halloalberta.de`,
                redirectUri: window.location.origin,
                clientId: '8ed3e739-6a47-4f4f-8e84-affc18f6f70a',
                responseType: 'code',
                scope: 'openid offline_access',
                showDebugInformation: false,
                clearHashAfterLogin: true,
                preserveRequestedRoute: true,
            },
            baseApiUrl: 'https://praxis.oktopus.k8s.api.halloalberta.de',
        },
    },
};

const env = getEnvironmentFromHost();

type AlbertConfig = {
    baseApiUrl: string;
    oauthConfig: AuthConfig;
    resourceServerUrls: string[];
    environment: string;
    version: string;
    tenant: 'Alberta' | 'Praxis';
};

export const config: FuseProviderConfig & AlbertConfig = {
    fuse: {
        layout: 'centered',
        scheme: 'light',
        screens: {
            sm: '600px',
            md: '960px',
            lg: '1280px',
            xl: '1440px',
        },
        theme: `theme-${env.tenant}`,
        themes: [
            {
                id: 'theme-Alberta',
                name: 'Alberta',
            },
            {
                id: 'theme-Praxis',
                name: 'Praxis',
            },
        ],
    },
    baseApiUrl: environment[env.tenant][env.env].baseApiUrl,
    oauthConfig: environment[env.tenant][env.env].oauthConfig,
    resourceServerUrls: [environment[env.tenant][env.env].baseApiUrl],
    environment: env.env,
    tenant: env.tenant,
    version: packageJson.version,
};

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideAuth(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        provideIcons(),
        provideFuse(config),

        {
            provide: ErrorHandler,
            useClass: OktopusErrorHandler,
        },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: OAuthStorage, useFactory: () => localStorage },
    ],
};

function getEnvironmentFromHost(): {
    env: string;
    tenant: 'Alberta' | 'Praxis';
} {
    const { host } = window.location;
    if (host.startsWith('localhost')) {
        return { env: 'dev', tenant: 'Alberta' };
        // return { env: 'dev', tenant: 'Praxis' };
    }

    if (
        [
            'dev.connect.halloalberta.de',
            'oktopus.dev.apps.halloalberta.de',
        ].includes(host)
    ) {
        return { env: 'dev', tenant: 'Alberta' };
    }

    if (
        [
            'dev.praxis.connect.halloalberta.de',
            'praxis.oktopus.dev.apps.halloalberta.de',
        ].includes(host)
    ) {
        return { env: 'dev', tenant: 'Praxis' };
    }

    if (
        [
            'staging.connect.halloalberta.de',
            'oktopus.staging.apps.halloalberta.de',
        ].includes(host)
    ) {
        return { env: 'staging', tenant: 'Alberta' };
    }

    if (
        [
            'staging.praxis.connect.halloalberta.de',
            'praxis.oktopus.staging.apps.halloalberta.de',
        ].includes(host)
    ) {
        return { env: 'staging', tenant: 'Praxis' };
    }

    if (
        ['connect.halloalberta.de', 'oktopus.apps.halloalberta.de'].includes(
            host
        )
    ) {
        return { env: 'prod', tenant: 'Alberta' };
    }

    if (
        [
            'praxis.connect.halloalberta.de',
            'praxis.oktopus.apps.halloalberta.de',
            'connect.procare-wundzentren.de',
        ].includes(host)
    ) {
        return { env: 'prod', tenant: 'Praxis' };
    }

    throw new Error(`Environment unknown for host ${host}`);
}
