import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { config } from 'app.config';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _oauthService: OAuthService,
        private _router: Router
    ) {
        void this.initOAuth();
    }

    private async initOAuth() {
        this._oauthService.configure(config.oauthConfig);
        this._oauthService.setupAutomaticSilentRefresh();

        const isAuthenticated =
            await this._oauthService.loadDiscoveryDocumentAndLogin({
                state: window.location.pathname,
            });
        if (isAuthenticated) {
            try {
                const tokenUserInfo =
                    (await this._oauthService.loadUserProfile()) as any;
                this._userService.user = {
                    id: tokenUserInfo.info.sub,
                    email: tokenUserInfo.info.email,
                    roles: tokenUserInfo.info.roles,
                } as any;
                if (this._oauthService.state) {
                    this._router.navigateByUrl(
                        decodeURIComponent(this._oauthService.state)
                    );
                }
            } catch (error) {
                this.signOut();
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        this._oauthService.logoutUrl = `${config.oauthConfig.issuer}/oauth2/logout?client_id=${config.oauthConfig.clientId}`;

        // Remove the access token from the local storage
        this._oauthService.logOut();

        // Return the observable
        return of(true);
    }
}
